// a library to wrap and simplify api calls
import apisauce from 'apisauce'
import AppConfig from '../config/AppConfig'
import routerStore from '../stores/RouterStore'
import Loading from '../utilities/loading'

// our "constructor"
const create = (baseURL = AppConfig.apiURL) => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      'Cache-Control': 'no-cache',
      'Accept': 'application/json'
    },
    // 20 second timeout...
    timeout: 20000
  })
  // api upload
  const apiUpload = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'multipart/form-data',
      'Accept': 'application/json'
    },
    // 20 second timeout...
    timeout: 20000
  })

  const monitoring = response => {
    Loading.hide()
    if (process.env.NODE_ENV === 'development') {
      console.log(response.config.method, response.config.url)
      console.log('response', response)
    }
    if (response.status === 401 && response.config.headers.Authorization) {
      routerStore.replace('/login')
    }
  }
  api.addMonitor(monitoring)
  apiUpload.addMonitor(monitoring)

  api.addRequestTransform(() => Loading.show())
  apiUpload.addRequestTransform(() => Loading.show())

  const setToken = (token) => {
    api.setHeader('Authorization', 'Bearer ' + token)
    apiUpload.setHeader('Authorization', 'Bearer ' + token)
  }

  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than "get", "post" and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  // auth
  const login = (data) => api.post('/auth/login', data)
  const changePassword = (data) => api.post('/auth/password', data)
  const refreshToken = (refreshToken) => api.post('/auth/refresh', { refresh_token: refreshToken })
  const getCurrentUser = () => api.get('/auth/me')

  const createClub = (data) => api.post(`/clubs`, data)
  const getClubs = (query) => api.get('/clubs', { query })
  const getClub = (id) => api.get(`/clubs/${id}`)
  const getClubPosts = (id, query) => api.get(`/clubs/${id}/posts`, { query })
  const getClubStories = (id, query) => api.get(`/clubs/${id}/stories`, { query })
  const updateClub = (id, data) => api.put(`/clubs/${id}`, data)
  const deleteClub = (id) => api.delete(`/clubs/${id}`)
  const uploadClubLogo = (id, formData) => apiUpload.post(`/clubs/${id}/upload-logo`, formData)
  const uploadClubMedia = (id, formData) => apiUpload.post(`/clubs/${id}/upload-media`, formData)
  const uploadMenu = (id, formData) => apiUpload.post(`/clubs/${id}/upload-menu`, formData)

  const getCities = (query) => api.get('/cities', { query })
  const createCity = (data) => api.post('/cities', data)
  const updateCity = (id, data) => api.put(`/cities/${id}`, data)

  const getTutorials = (query) => api.get('/tutorials', { query })
  const createTutorial = (data) => api.post('/tutorials', data)
  const updateTutorial = (id, data) => api.put(`/tutorials/${id}`, data)
  const deleteTutorial = (id) => api.delete(`/tutorials/${id}`)


  const getRewards = (query) => api.get('/rewards', { query })
  const createReward = data => api.post('/rewards', data)
  const deleteReward = id => api.delete(`/rewards/${id}`)
  const getReward = id => api.get(`/rewards/${id}`)
  const updateReward = (id, data) => api.put(`/rewards/${id}`, data)

  const getRewardRequestByUserId = (id, query) => api.get(`/reward/request/user/${id}`, { query })

  const createUser = (data) => api.post(`/users`, data)
  const getUsers = (params) => api.get('/users', params)
  const getUser = (id) => api.get(`/users/${id}`)
  const getUserPosts = (id, query) => api.get(`/users/${id}/posts`, { query })
  const updateUser = (id, data) => api.put(`/users/${id}`, data)
  const uploadAvatar = (id, formData) => apiUpload.post(`/users/${id}/upload-avatar`, formData)
  const deleteUser = (id) => api.delete(`/users/${id}`)

  const getPosts = (params) => api.get('/posts', params)
  const deletePost = (id) => api.delete(`/posts/${id}`)
  const updatePost = (id, data) => api.put(`/posts/${id}`, data)

  const getReports = (params) => api.get('/reports', params)
  const deleteReport = (id) => api.delete(`/reports/${id}`)

  const getSummary = () => api.get('/summary')
  const getClubSummary = (id) => api.get(`/summary/${id}`)

  const getReservations = (params) => api.get(`/reservations`, params)

  const sendNotification = (data) => api.post(`/notifications`, data)

  const getPoints = (userId) => api.get(`/points/${userId}`)
  const addPoint = (data) => api.post(`/points`, data)
  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back th\e `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    // a list of the API functions from step 2setToken,
    setToken,
    // auth
    login,
    changePassword,
    refreshToken,
    getCurrentUser,

    // club
    createClub,
    getClubs,
    getClub,
    updateClub,
    deleteClub,
    uploadClubLogo,
    uploadClubMedia,
    uploadMenu,
    getClubPosts,
    getClubStories,

    // city
    getCities,
    createCity,
    updateCity,

    // tutorial
    getTutorials,
    createTutorial,
    updateTutorial,
    deleteTutorial,

    // Rewards
    getRewards,
    createReward,
    deleteReward,
    getReward,
    updateReward,

    // RewardRequest
    getRewardRequestByUserId,

    // user
    createUser,
    getUsers,
    getUser,
    updateUser,
    deleteUser,
    getUserPosts,
    uploadAvatar,

    getPosts,
    deletePost,
    updatePost,

    getReports,
    deleteReport,

    getSummary,
    getClubSummary,

    getReservations,

    sendNotification,

    getPoints,
    addPoint
  }
}

// let's return back our create method as the default.
export default {
  create
}
