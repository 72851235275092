import { observable, action } from 'mobx'
import api from '../services/ApiDefault'
import { showResponseError, showError } from '../utilities/utils'
import routerStore from './RouterStore'

export class RewardStore {
  @observable isLoading = false
  @observable isUploading = false

  @observable reward = null
  @observable rewards = []

  @action async createReward(data) {
    this.isLoading = true
    const response = await api.createReward(data)
    if (response.ok) {
      await this.getRewards()
      // routerStore.replace(`/rewards/${response.data.data._id}`)
      routerStore.replace(`/rewards`)
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async getRewards() {
    this.isLoading = true
    const query = {
      // with: ['host', 'waiter']
    }
    const response = await api.getRewards(query)
    if (response.ok) {
      this.rewards = response.data.data
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async getReward(id, isStarting) {
    this.isLoading = true
    if (isStarting) {
      this.reward = null
    }
    const response = await api.getReward(id)
    this.isLoading = false
    if (response.ok) {
      this.reward = response.data.data
      return this.reward
    } else {
      showResponseError(response)
    }
  }

  @action async deleteReward(id) {
    const response = await api.deleteReward(id)
    if (response.ok) {
      routerStore.replace('/rewards')
      this.reward = null
      await this.getRewards()
    } else {
      if (response.status === 422) {
        showError('Can not delete reward with posts, try disable it instead')
      } else {
        showResponseError(response)
      }
    }
  }

  @action async updateReward(id, data) {
    const response = await api.updateReward(id, data)
    if (response.ok) {
      this.reward = response.data.data
      await this.getRewards()
      routerStore.push(`/rewards/${this.reward._id}`)
    } else {
      showResponseError(response)
    }
  }
}

const rewardStore = new RewardStore()
export default rewardStore
