import { observable, action } from 'mobx'
// import { ignore } from 'mobx-sync'
import api from '../services/ApiDefault'
import { showResponseError, showError } from '../utilities/utils'
import routerStore from './RouterStore'
// import Loading from '../utilities/loading'
import postStore from './PostStore'

export class TutorialStore {
  @observable isLoading = false
  @observable isUploading = false

  @observable tutorials = []

  @action async createTutorial(data) {
    this.isLoading = true
    const response = await api.createTutorial(data)
    if (response.ok) {
      await this.getTutorials()
      routerStore.replace(`/tutorials`)
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async getTutorials() {
    this.isLoading = true
    const query = {
    }
    const response = await api.getTutorials(query)
    if (response.ok) {
      this.tutorials = response.data.data
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async updateTutorial(id, data) {
    const response = await api.updateTutorial(id, data)
    if (response.ok) {
      await this.getTutorials()
      if (!data.status) {
        routerStore.push(`/tutorials`)
      }
    } else {
      showResponseError(response)
    }
  }

  @action async deleteTutorial(id) {
    const response = await api.deleteTutorial(id)
    if (response.ok) {
      await this.getTutorials()
    } else {
      showResponseError(response)
    }
  }
}

const tutorialStore = new TutorialStore()
export default tutorialStore
