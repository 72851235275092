import { observable, action } from 'mobx'
// import { ignore } from 'mobx-sync'
import api from '../services/ApiDefault'
import { showResponseError, showError } from '../utilities/utils'
import routerStore from './RouterStore'
// import Loading from '../utilities/loading'

export class CityStore {
  @observable isLoading = false
  @observable isUploading = false

  @observable cities = []

  @action async createCity (data) {
    this.isLoading = true
    const response = await api.createCity(data)
    if (response.ok) {
      await this.getCities()
      routerStore.replace(`/cities`)
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async getCities () {
    this.isLoading = true
    const query = {}
    const response = await api.getCities(query)
    if (response.ok) {
      this.cities = response.data.data
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async updateCity (id, data) {
    const response = await api.updateCity(id, data)
    if (response.ok) {
      await this.getCities()
      if (!data.status) {
        routerStore.push(`/cities`)
      }
    } else {
      showResponseError(response)
    }
  }
}

const cityStore = new CityStore()
export default cityStore
