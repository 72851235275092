import { observable, action } from 'mobx'
import api from '../services/ApiDefault'
import { showResponseError, showError } from '../utilities/utils'

export class rewardRequestStore {
  @observable isLoading = false

  @observable rewardRequest = null
  @observable rewardsRequest = []

  @action async getRewardRequestByUserId(userId) {
    this.isLoading = true
    const query = {
      with: ['reward']
    }
    const response = await api.getRewardRequestByUserId(userId, query)
    if (response.ok) {
      this.rewardsRequest = response.data.data
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }
}

export default rewardRequestStore = new rewardRequestStore()
